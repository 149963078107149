<template>
  <div>
    <header class="main_header_arae">
      <!-- Top Bar -->
      <div class="topbar-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <ul class="topbar-list">
                <li>
                  <a href="#!"><i class="fab fa-facebook"></i></a>
                  <a href="#!"><i class="fab fa-twitter-square"></i></a>
                  <a href="#!"><i class="fab fa-instagram"></i></a>
                  <a href="#!"><i class="fab fa-linkedin"></i></a>
                </li>
                <li class="hello">
                  <a href="#!"
                    ><span class="child-hello">+011 234 567 89</span></a
                  >
                </li>
                <li>
                  <a href="#!"><span>contact@domain.com</span></a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6">
              <ul class="topbar-others-options">
                <li v-if="!login">
                  <router-link to="/login">Login | Sign up</router-link>
                </li>
                <li v-else>
                  <router-link to="/my-profile">Profile</router-link>
                </li>
                <li v-if="login">
                  <p class="logout">{{ dataUser }} Neo Points</p>
                </li>
                <li>
                  <div class="dropdown language-option">
                    <select v-model="language">
                      <option value="Spanish">Español</option>
                      <option value="English">English</option>
                    </select>
                  </div>
                </li>
                <li>
                  <div class="dropdown language-option">
                    <select v-model="currency">
                      <option value="USD">USD</option>
                      <option value="BD">BD</option>
                      <option value="URO">URO</option>
                    </select>
                  </div>
                </li>
                <li v-if="isLogin" @click="logout">
                  <p class="logout">Logout</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Navbar Bar -->
      <div class="navbar-area" :class="{ 'is-sticky': isSticky }">
        <div class="main-responsive-nav">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo">
                    <router-link to="/">
                      <img
                        src="@/assets/img/logo-white.svg"
                        width="120px"
                        alt="logo"
                      />
                    </router-link>
                  </div>
                  <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-content"
                  >
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div
                    class="collapse navbar-collapse mean-nav"
                    id="navbar-content"
                  >
                    <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                      <li class="nav-item dropdown">
                        <router-link to="/" class="nav-link">
                          {{ $t("home.housing") }}
                        </router-link>
                      </li>
                      <!-- <li class="nav-item dropdown">
                        <router-link
                          to="/"
                          class="dropdown-item dropdown-toggle"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          >Tours</router-link
                        >
                        <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link to="/tour-search" class="dropdown-item"
                              >Tour</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/tour-details"
                              class="dropdown-item"
                              >Tour Details</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/tour-booking-submission"
                              class="dropdown-item"
                              >Tour Booking</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/top-destinations"
                              class="dropdown-item"
                              >Top Destination</router-link
                            >
                          </li>
                        </ul>
                      </li> -->
                      <!-- <li class="nav-item dropdown">
                        <router-link
                          to="/"
                          class="dropdown-item dropdown-toggle"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          >Flights</router-link
                        >
                        <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link
                              to="/flight-search-result"
                              class="dropdown-item"
                              >Flight</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/flight-booking-submission"
                              class="dropdown-item"
                              >Flight Booking</router-link
                            >
                          </li>
                        </ul>
                      </li> -->
                      <li class="nav-item dropdown">
                        <!-- <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link
                              to="/hotel-search"
                              class="dropdown-item"
                              >{{ $t("home.housing") }}</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/hotel-details"
                              class="dropdown-item"
                              >Hotel Booking</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/room-details"
                              class="dropdown-item"
                              >Room Details</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/room-booking"
                              class="dropdown-item"
                              >Room Booking</router-link
                            >
                          </li>
                        </ul> -->
                      </li>
                      <!-- <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          >Pages</a
                        >
                        <ul class="dropdown-menu shadow">
                          <li class="nav-item">
                            <router-link to="/about" class="dropdown-item"
                              >About</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/tour-guides" class="dropdown-item"
                              >Team</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/testimonials"
                              class="dropdown-item"
                              >Testimonials</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/faqs" class="dropdown-item"
                              >FAQ</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/booking-confirmation"
                              class="dropdown-item"
                              >Booking Confirmation</router-link
                            >
                          </li>

                          <li class="dropend">
                            <a
                              href="#"
                              class="dropdown-item dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              >News</a
                            >
                            <ul class="dropdown-menu shadow">
                              <li class="nav-item">
                                <router-link to="/news" class="dropdown-item"
                                  >News</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link to="/news-v2" class="dropdown-item"
                                  >News v2</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/news-details"
                                  class="dropdown-item"
                                  >News Details</router-link
                                >
                              </li>
                            </ul>
                          </li>
                          <li class="dropend">
                            <a
                              href="#"
                              class="dropdown-item dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              >User Pages</a
                            >
                            <ul class="dropdown-menu shadow">
                              <li class="nav-item">
                                <router-link to="/login" class="dropdown-item"
                                  >Login</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/register"
                                  class="dropdown-item"
                                  >Register</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/forgot-password"
                                  class="dropdown-item"
                                  >Forget Password</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/verify-otp"
                                  class="dropdown-item"
                                  >Verify OTP</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/reset-password"
                                  class="dropdown-item"
                                  >Reset Password</router-link
                                >
                              </li>
                            </ul>
                          </li>
                          <li class="dropend">
                            <a
                              href="#"
                              class="dropdown-item dropdown-toggle"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              >Customer Dashboard</a
                            >
                            <ul class="dropdown-menu shadow">
                              <li class="nav-item">
                                <router-link
                                  to="/dashboard"
                                  class="dropdown-item"
                                  >Dashboard</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/hotel-booking"
                                  class="dropdown-item"
                                  >Hotel booking</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/flight-booking"
                                  class="dropdown-item"
                                  >Flight booking</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/tour-booking"
                                  class="dropdown-item"
                                  >Tour booking</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/booking-history"
                                  class="dropdown-item"
                                  >Booking history</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/my-profile"
                                  class="dropdown-item"
                                  >My profile</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link to="/wallet" class="dropdown-item"
                                  >Wallet</router-link
                                >
                              </li>
                              <li class="nav-item">
                                <router-link
                                  to="/notification"
                                  class="dropdown-item"
                                  >Notifications</router-link
                                >
                              </li>
                            </ul>
                          </li>
                          <li class="nav-item">
                            <router-link
                              to="/privacy-policy"
                              class="dropdown-item"
                              >Privacy Policy</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/error" class="dropdown-item"
                              >404 Error</router-link
                            >
                          </li>
                        </ul>
                      </li> -->
                      <li class="nav-item">
                        <router-link to="/contact" class="dropdown-item"
                          >Contact</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <router-link class="navbar-brand" to="/">
                <img
                  src="../assets/img/logo-white.svg"
                  width="120px"
                  alt="logo"
                />
              </router-link>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">
                      {{ $t("home.housing") }}
                      <!-- <i class="fas fa-angle-down"></i> -->
                    </router-link>
                    <!-- <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/" class="nav-link"
                          >Home One</router-link
                        >
                      </li>
                    </ul> -->
                  </li>
                  <!-- <li class="nav-item">
                    <a href="#" class="nav-link">
                      Tours
                      <i class="fas fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/tour-search" class="nav-link"
                          >Tour</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/tour-details" class="nav-link"
                          >Tour Details</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link
                          to="/tour-booking-submission"
                          class="nav-link"
                          >Tour Booking</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/top-destinations" class="nav-link"
                          >Top Destination</router-link
                        >
                      </li>
                    </ul>
                  </li> -->
                  <!-- <li class="nav-item">
                    <a href="#" class="nav-link">
                      Flights
                      <i class="fas fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/flight-search-result" class="nav-link"
                          >Flight</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link
                          to="/flight-booking-submission"
                          class="nav-link"
                          >Flight Booking</router-link
                        >
                      </li>
                    </ul>
                  </li> -->
                  <!-- <li class="nav-item">
                    <a href="#" class="nav-link">
                      Pages
                      <i class="fas fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/about" class="nav-link"
                          >About</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/tour-guides" class="nav-link"
                          >Team</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/testimonials" class="nav-link"
                          >Testimonials</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/faqs" class="nav-link"
                          >FAQ</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/booking-confirmation" class="nav-link"
                          >Booking Confirmation</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <a to="#" class="nav-link">News</a>
                        <ul class="dropdown-menu">
                          <li class="nav-item">
                            <router-link to="/news" class="nav-link"
                              >News</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/news-v2" class="nav-link"
                              >News v2</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/news-details" class="nav-link"
                              >News Details</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a to="#" class="nav-link">User Pages</a>
                        <ul class="dropdown-menu">
                          <li class="nav-item">
                            <router-link to="/login" class="nav-link"
                              >Login</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/register" class="nav-link"
                              >Register</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/forgot-password" class="nav-link"
                              >Forget Password</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/verify-otp" class="nav-link"
                              >Verify OTP</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/reset-password" class="nav-link"
                              >Reset Password</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link">Customer Dashboard</a>
                        <ul class="dropdown-menu">
                          <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link"
                              >Dashboard</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/hotel-booking" class="nav-link"
                              >Hotel booking</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/flight-booking" class="nav-link"
                              >Flight booking</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/tour-booking" class="nav-link"
                              >Tour booking</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/booking-history" class="nav-link"
                              >Booking history</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/my-profile" class="nav-link"
                              >My profile</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/wallet" class="nav-link"
                              >Wallet</router-link
                            >
                          </li>
                          <li class="nav-item">
                            <router-link to="/notification" class="nav-link"
                              >Notifications</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <router-link to="/privacy-policy" class="nav-link"
                          >Privacy Policy</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/error" class="nav-link"
                          >404 Error</router-link
                        >
                      </li>
                    </ul>
                  </li> -->
                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link"
                      >Contact</router-link
                    >
                  </li>
                </ul>
                <!-- <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <router-link to="/become-vendor" class="btn btn_navber">{{
                      $t("home.becomePartner")
                    }}</router-link>
                  </div>
                </div> -->
              </div>
            </nav>
          </div>
        </div>
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="container">
              <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <a href="#" class="search-box"
                      ><i class="fas fa-search"></i
                    ></a>
                  </div>
                  <div class="option-item">
                    <router-link to="/contact" class="btn btn_navber"
                      >Get free quote</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import router from "@/router";

const userStore = useUserStore();

const { isLogin } = storeToRefs(userStore);

const login = computed(() => isLogin.value);

const language = ref("English");
const currency = ref("USD");
const isSticky = ref(false);

/* const neoPoints = computed(() => {
  return userData.value?.Data?.neo_Dollars_redeemable_points;
}); */

const neoPoints = ref(0);

const logout = () => {
  localStorage.removeItem("userToken");
  isLogin.value = false;
  router.push("/");
};

const dataUser = computed(() => {
  const data: any = userStore.dataProfile;
  neoPoints.value = data.neo_Dollars_redeemable_points;

  return data.neo_Dollars_redeemable_points;
});

if (localStorage.getItem("userToken")) {
  userStore.fetchDataUser();
}

onMounted(async () => {
  /* if (localStorage.getItem("userToken")) {
    const data = await userStore.fetchDataUser();
    console.log(data);
    //neoPoints.value = .neo_Dollars_redeemable_points;
  } */

  window.addEventListener("scroll", () => {
    let scroll = window.scrollY;
    if (scroll >= 200) {
      isSticky.value = true;
    } else {
      isSticky.value = false;
    }
  });

  document.addEventListener("click", (e: Event) => {
    const target = e.target as HTMLElement;
    if (target && target.classList.contains("hamburger-toggle")) {
      const children = target.children[0] as HTMLElement;
      if (children) {
        children.classList.toggle("active");
      }
    }
  });
});
</script>

<style scoped>
.logout {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.logout:hover {
  color: #000;
}
</style>
