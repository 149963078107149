import { createApp } from "vue";
import { createPinia } from "pinia";
import { i18n } from "./plugins/i18n";
import router from "./router";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import vueCountryRegionSelect from "vue3-country-region-select";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import StarRating from 'vue-star-rating'
// Main CSS Add
import "./assets/app.css";
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vueCountryRegionSelect);
app.use(VueTelInput);
app.component('star-rating', StarRating.default)
app.use(Vue3Toastify, {
  autoClose: 5000,
} as ToastContainerOptions);

app.mount("#app");
